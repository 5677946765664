<div
  class="copy-container"
  [attr.data-copy-content]="copyValue"
  (click)="copy($event)"
  [igTooltip]="(showSuccessMessage ? 'generell_clipboard_copy_done' : 'generell_clipboard_copy') | uitranslate"
  [tooltipMode]="(showSuccessMessage ? 'success': 'default')"
>
  <div [innerHTML]="viewContent"></div> 

  <div class="p-ml-1">
    <i class="fal" [ngClass]="{'fa-copy': !showSuccessMessage, 'fa-check-circle ig-color-success-green': showSuccessMessage}"></i>
  </div>
  
  <div #contentWrapper style="display:none">
      <ng-content></ng-content>
  </div>


  <!--<div class="ig-copy-clipboard--tooltip" [ngClass]="{nobottomspace: nobottomspace}" #tooltip>
      <div class="undone"><i class="fal fa-copy"></i> {{'generell_clipboard_copy' | uitranslate}}</div>
      <div class="done"><i class="fal fa-check-circle"></i> {{'generell_clipboard_copy_done' | uitranslate}}</div>
  </div>-->
</div>

import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectorRef,
  DoCheck,
  AfterViewInit,
  ChangeDetectionStrategy,
  NgZone,
  inject,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'ig-copy-clipboard',
  templateUrl: './copy-clipboard.component.html',
  styleUrls: ['./copy-clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyClipboardComponent implements DoCheck, AfterViewInit {
  /**
   * @ignore
   */
  value?: string;

  /**
   * @ignore
   */
  @ViewChild('contentWrapper', { static: false }) content?: ElementRef;

  /**
   * @ignore
   */
  @ViewChild('tooltip', { static: false }) tooltipEl?: ElementRef;

  /**
   * Value to copy if it is not the same as the inner content.
   * Also bind value to a property if you want the displayed content to change after viewInit
   * Hint: Setting `value` will disable change detection on every view cycle, if you want to force change detection set `static` to false
   */
  @Input('value')
  set _value(_value: string) {
    this.value = _value;
    this.updateView();
  }
  /**
   * set to true if the copy tooltip should not appear below the element
   */
  @Input() nobottomspace = false;

  /**
   * if the content of the component is static (=never changes during lifetime of the component) set this to `true` to disable
   * change detection on every view cycle (will save resources)
   * or set it to `false` to force change detection on every view cycle even if `value` is set
   */
  @Input() static?: boolean;
  /**
   * @ignore
   */
  copyValue?: string;
  /**
   * @ignore
   */
  viewContent?: SafeHtml;
  /**
   * @ignore
   */
  showSuccessMessage = false;

  private oldValue?: string;
  private oldTextContent?: string;

  private debouncer?: number;

  readonly #sanitizer = inject(DomSanitizer);

  constructor(private ref: ChangeDetectorRef, private zone: NgZone) {}

  copy(e: Event) {
    this.zone.runOutsideAngular(() => {
      const target = e.currentTarget as HTMLElement | undefined;

      if (target?.hasAttribute('data-copy-content')) {
        const content = target?.getAttribute('data-copy-content');

        if (content) {
          navigator.clipboard
            .writeText(content)
            .then(() => {
              this.showSuccessMessage = true;
              this.ref.markForCheck();
              setTimeout(() => {
                this.showSuccessMessage = false;
                this.ref.markForCheck();
              }, 1000);
            })
            .catch(() =>
              window.prompt('Copy to clipboard: Ctrl+C, Enter', content)
            );
        }
      } else if (this.content) {
        const content = this.content.nativeElement.textContent.trim();
        navigator.clipboard
          .writeText(content)
          .then(() => {
            this.showSuccessMessage = true;
            this.ref.markForCheck();
            setTimeout(() => {
              this.showSuccessMessage = false;
              this.ref.markForCheck();
            }, 1000);
          })
          .catch(() =>
            window.prompt('Copy to clipboard: Ctrl+C, Enter', content)
          );
      }
    });
  }

  ngAfterViewInit() {
    this.updateView();
  }

  /**
   * @ignore
   */
  ngDoCheck() {
    if ((this.value && this.static !== false) || this.static === true) {
      // no need for this exhausting check
      return;
    }

    if (!this.debouncer) {
      this.zone.runOutsideAngular(() => {
        this.debouncer = window.setTimeout(() => {
          let changes = false;

          if (this.value !== this.oldValue) {
            this.oldValue = this.value;
            changes = true;
          }

          if (
            this.content &&
            this.content.nativeElement.textContent !== this.oldTextContent
          ) {
            this.oldTextContent = this.content.nativeElement.textContent;
            changes = true;
          }

          if (changes) {
            this.updateView();
          }
          this.debouncer = undefined;
        }, 100);
      })
    }
  }
  /**
   * @ignore
   */
  updateView() {
    this.zone.runOutsideAngular(() => {
      window.setTimeout(() => {
        if (this.content) {
          this.viewContent = this.#sanitizer.bypassSecurityTrustHtml(
            this.content.nativeElement.innerHTML
          );
          this.ref.markForCheck();
        }
      }, 10);

      if (this.content && this.value === undefined) {
        this.copyValue = this.content.nativeElement.textContent.trim();
        this.ref.markForCheck();
      } else {
        if (this.copyValue !== this.value) {
          this.copyValue = this.value;
          this.ref.markForCheck();
        }
      }
    })

  }
}

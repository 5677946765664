import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyClipboardComponent } from './copy-clipboard.component';
import { SharedModule } from '../../helper/shared.module';
import { TooltipModule } from '../../elements/tooltip/tooltip.module';



@NgModule({
  declarations: [CopyClipboardComponent],
  imports: [
    CommonModule,
    TooltipModule,
    SharedModule
  ],
  exports: [CopyClipboardComponent]
})
export class CopyClipboardModule { }
